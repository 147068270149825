.shake {
    animation-name: tilt-shaking;
    animation-duration: 0.1s;
    animation-delay: 0.2s;
    animation-iteration-count: 5;
}

@keyframes shake-horizontal {
    0% {
        transform: translateX(0%);
    }

    25% {
        transform: translateX(-1%);
    }

    50% {
        transform: translateX(1%);
    }

    75% {
        transform: translateX(-1%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(1deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-1deg);
    }

    100% {
        transform: rotate(0deg);
    }
}